* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* 1rem = 10px, 10px/16px = 62.5% */
}

body {
  font-family: "Avenir";
  font-weight: 400;
  line-height: 1.6;
  scroll-behavior: smooth;
  background-color: #F5F5F5;
  color: #0C0E26;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.SnackbarContainer-root {
  z-index: 3000 !important;
}

.spinner {
  animation: spin infinite 5s linear;

  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes spin {
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
}

.react-select__menu-portal {
  z-index: 10001 !important;
}

.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(12, 14, 38, 0.6);
  z-index: 7999;  
}
